import { RawPartsImport } from 'src/app/modules/admin/sub-routes/import-raw-parts/import-raw-parts.interface';
import { SCAQueue } from './sca-queue.interface';

export enum AppSyncEventType {
	CONNECTION_INIT = 'connection_init',
	CONNECTION_ACK = 'connection_ack',
	KA = 'ka',
	DATA = 'data',
	SUBSCRIBE = 'subscribe',
	SUBSCRIBE_SUCCESS = 'subscribe_success',
	SUBSCRIBE_ERROR = 'subscribe_error',
	UNSUBSCRIBE = 'unsubscribe',
	UNSUBSCRIBE_SUCCESS = 'unsubscribe_success',
	UNSUBSCRIBE_ERROR = 'unsubscribe_error',
	PUBLISH = 'publish',
	PUBLISH_SUCCESS = 'publish_success',
	PUBLISH_ERROR = 'publish_error'
}

export enum AppSyncChannels {
	DEFAULT = '/default/*'
}

export type AppSyncEventHeader = {
	host: string;
	Authorization: string;
};

export type AppSyncEventError = {
	errorType: string;
	message: string;
};

export type AppSyncEvent = {
	type: AppSyncEventType;
	authorization?: AppSyncEventHeader;
	channel?: string;
	id?: string;
	connectionTimeoutMs?: number;
	event?: string | string[];
	errors?: AppSyncEventError[];
};

export enum AppSyncMessageEnum {
	RAW_PARTS_IMPORT = 'rawPartsImport',
	SCA_QUEUE = 'scaQueue'
}

export type AppSyncMessageTypes = {
	[AppSyncMessageEnum.RAW_PARTS_IMPORT]: RawPartsImport;
	[AppSyncMessageEnum.SCA_QUEUE]: SCAQueue;
};
